<template>
<main>
  <div class="logo-area">
    <img src="@/assets/icons/logo.svg" class="logo" />
  </div>
  <div class="game-selector">
    <div class="title">Επιλέξτε παιχνίδι: </div>
    <div class="games">
      <router-link to="trivia" class="game">
        <img src="@/assets/icons/games/trivia.png" alt="trivia" />
        <div class="game-title">DIGITAL TRIVIA</div>
      </router-link>
      <router-link to="truths-lies" class="game">
        <img src="@/assets/icons/games/truths-lies.png" alt="truths-lies" />
        <div class="game-title">2 ΑΛΗΘΕΙΕΣ & 1 ΨΕΜΑ</div>
      </router-link>
<!--      <router-link to="levia" class="game">
        <img src="@/assets/icons/games/levia.png" alt="levia" />
        <div class="game-title">LEVIA</div>
      </router-link>-->
      <router-link to="iluma-iprime" class="game">
        <img src="@/assets/icons/games/iluma.png" alt="levia" />
        <div class="game-title">IQOS ILUMA i PRIME</div>
      </router-link>
      <router-link to="randomizer" class="game">
        <img src="@/assets/icons/games/randomizer.png" alt="randomizer" />
        <div class="game-title">RANDOMIZER</div>
      </router-link>
<!--      <router-link to="color-quiz" class="game">
        <img src="@/assets/icons/games/illuminate.png" alt="color quiz" />
        <div class="game-title">COLOR QUIZ</div>
      </router-link>-->
    </div>
  </div>
  <div class="user-data">You are logged in as: {{user}}. <router-link to="stats" v-if="admin">Stats Panel</router-link> <router-link to="logout">Logout</router-link></div>
</main>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import ls from 'localstorage-slim';
import { useHead } from "@unhead/vue";

const user = ref('');

const admin = ref( false );

onMounted(() => {
  const userData = ls.get('user', {decrypt: true});

  const userMatch = userData?.data?.match(/UserName:(.*?)\w+/);

  if( userMatch && userMatch[ 0 ] ) {
    user.value = userMatch[ 0 ].replace('UserName:', '');
  }

  admin.value = userData?.data?.indexOf('Admin:true') > -1;

});

useHead({
  title: 'Activation Arena'
});

</script>

<style lang="scss" scoped>

.logo-area {
  position: fixed;
  top: 20px;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  z-index: 10;
  left: 0;

  @media screen and (min-height: 780px) {
    top: 30px;
  }
}
.logo {
  height: 26px;
}

main {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;
}

.game-selector {
  //width: 100%;
  //height: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: column;
  gap: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 70px);
  padding: 15px 0;
}

.games {
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.game {
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding: 16px 24px;
  border-radius: 21px;
  background: var(--iqos-slate, #34303D);
  align-items: center;
  box-shadow: 0px 6px 10px 0px rgba(18, 191, 193, 0.02), 0px -2px 0.2px 0.2px rgba(111, 244, 248, 0.40), 0px -0.5px 2px 0px rgba(192, 248, 250, 0.20), 0px 0px 26.446px 0px rgba(18, 191, 193, 0.10);
  text-decoration: none;
  max-width: 100%;

  .game-title {
    color: var(--iqos-slate-15, #E1E1E3);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 25.6px */
  }
}

.title {
  color: var(--iqos-white, #FFFDFB);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-align: center;

  @media screen and (min-width: 1024px) {
    text-align: center;
  }
}

.user-data {
  position: fixed;
  bottom: 5px;
  right: 0;
  font-size: 14px;
  font-weight: 400;
  padding: 0 24px;
}

a {
  color: var(--iqos-white, #FFFDFB);
}

</style>
